@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
    margin: 0;
    padding: 0;
}

#app, #root, body, html {
    min-height: 100vh;
    width: 100%;
    height: 100%;
    position: relative;
    font-family: 'Raleway', sans-serif;
    background-color: #e6e6e6;
}

